




import LookUp from '@/components/lookup/Index.vue'
import { authPermissions } from '@/constants'
import { Page } from '@/types/enums'
import { SearchConfigField } from '@/types/utilTypes'
import fieldsObj from '@/views/Accounting.json'
import Vue from 'vue'

export default Vue.extend({
    name: 'Accounting',
    components: {
        LookUp
    },
    data(){
        return {
            options:{
                name: 'accounting',
                api: '/Accounting/GetAccounting',
                exportAPI: {
                    api: '/Accounting/ExportAccounting',
                    requiredPermissions: [authPermissions.canExportAll]
                },
                pivotTableAPI: {
                    api:'',
                    params: {
                        source: ''
                    }},
                quantumChartsAPI: {
                    api: '/Accounting/GetAccountingJson',
                    requiredPermissions: [authPermissions.canAccessAllQuantumCharts]
                },
                showTotal: true,
                fields: fieldsObj.fields as SearchConfigField[],
                keywordSearchPlaceholder: 'Enter Primary Appraiser or Job Id or Address or City or Province',
                gmapOptions: {
                    showGmaps: true,
                    exportIdField: 'Job Id',
                    page: Page.ACCOUNTING,
                    idFieldKey: 'jobId'
                },
                enableEditor: true
            }
        }
    },
})
